<template>
  <div class="rounded-md bg-green-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <i class="far fa-exclamation-triangle text-green-800"></i>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-green-800">{{ text }}</p>
      </div>
      <div class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button type="button" class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50">
            <span class="sr-only">Fermer</span>
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MvpNotification',
  props: {
    type: {
      type: String
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      defaullt: 'Lorem ipsum'
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
