<template>
  <div id="app" class="min-h-screen">
    <notificationGroup group="maviepro-success">
      <div
        class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-center z-50"
      >
        <div class="max-w-lg w-full">
          <notification v-slot="{ notifications, close }">
            <mvp-notification
              v-for="notification in notifications"
              :key="notification.id"
              type="success"
              :text="notification.text"
              :title="notification.title"
            />
          </notification>
        </div>
      </div>
    </notificationGroup>
    <notificationGroup group="maviepro-error">
      <div
        class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-center z-50"
      >
        <div class="max-w-lg w-full">
          <notification v-slot="{ notifications, close }">
            <div
              class="rounded-md bg-red-50 p-4"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex">
                <div class="flex-shrink-0">
                  <i class="far fa-exclamation-triangle text-red-800"></i>
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-red-800">{{ notification.text }}</p>
                </div>
                <div class="ml-auto pl-3">
                  <div class="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                      @click="close(notification.id)"
                    >
                      <span class="sr-only">Fermer</span>
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>
    <router-view/>
  </div>
</template>

<script>
import MvpNotification from './components/TheNotification.vue'

export default {
  name: 'App',
  components: { MvpNotification },
  mounted () {
    this.$store.dispatch('FETCH_APPLICATION')
    if (this.$store.getters.isAuthenticated) {
      this.$store.dispatch('FETCH_DOMAIN')
      this.$store.dispatch('FETCH_USER')
    }
  }
}
</script>

<style lang="scss">
.details {
  margin-top: 20px;
}
.page-header {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page-content {
  padding: 20px;
}
.page-content-box {
  background-color: #fff;
  // box-shadow: 0 1em 2em rgba(0, 0, 0, .05);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .1),
    0 0px 0 1px rgba(10, 10, 10, .02);
}

.actions {
  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.ql-editor p {
  margin-bottom: 6px!important;
}

.auth {
  .hero-body {
    justify-content: center;
    flex-direction: column;
    color: whitesmoke;

    a {
      color: whitesmoke;
    }
  }
  .login-hr {
    border-bottom: 1px solid black;
  }
  .auth-block {
    max-width: 320px;
  }
  .auth-title {
    color: white;
    font-size: 60px;
    letter-spacing: -4px;
    text-align: center;
    font-weight: bold;
  }
  .auth-form {
    background: rgba(255, 255, 255, .1);
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.2),
      0 0 0 1px rgba(10,10,10,.02);

    p {
      margin: 5px 0;
    }
  }
  a.auth-link {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
    opacity: .7;
    color: white;

    &:hover {
      opacity: 1;
      color: white;
    }
  }
}
</style>
